<template>
    <div class="orgcontainer">
        <div class="texts">
            <h2> تماس با ما </h2>
            <span class="body"> برای ارتباط با تیم صرافی از راه های زیر در ارتباط باشید </span>
        </div>
        <div class="content">
            <div class="infos">
                <div class="title">
                    <span>راه های ارتباطی</span>
                    <div class="underline"></div>
                </div>
                <div class="info">
                    <div class="phone">
                        <img class="phonesvg" src="../../assets/icons/phone.svg" alt="">
                        <div class="nums">
                            <span> {{info.number}} </span>
                            <span> پاسخگویی هر روز ۸ تا ۱۲ </span>
                        </div>
                    </div>
                    <div class="gmail">
                        <img class="emailsvg" src="../../assets/icons/email.svg" alt="">
                        <span> {{info.email ? info.email.replace('https://','') : ''}} </span>
                    </div>
                </div>
                <div class="social">
                    <img src="../../assets/icons/facewhite.svg" alt="">
                    <img src="../../assets/icons/whatswhite.svg" alt="">
                    <img src="../../assets/icons/telewhite.svg" alt="">
                    <img src="../../assets/icons/instawhite.svg" alt="">
                </div>
            </div>
            <img class="contactbanner" src="../../assets/map.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name:'ContactUs',
    data() {
        return {
            info:[],
        }
    },
    methods:{
        async contantUS(){
            const res = await this.$axios.get('/exchange-info',)
            this.info = res.baseOTD || {
                address: '',
                    appAndroid: '',
                    facebook:'',
                    appIOS: '',
                    email: 'exchange@mail.com',
                    instagram: '',
                    linkedin: '',
                    number: '22234234 - 021 ',
                    telegram: '',
                    twitter: ''
            }
        }
    },
    mounted() {
            this.contantUS();
    },
}
</script>


<style lang="scss" scoped>
.social{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.gmail{
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    span{
        font-size: clamp(10px,1vw,12px);
    }
}
.content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img{
        z-index: 4;
    }
    .infos{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background: var(--dark-blue);
        z-index: 7;
        color: white;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 35px;
        width: 20%;
        .info{
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            .phone{
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 16px;
                .nums{
                    display: flex;
                    flex-direction: column;
                    row-gap: 5px;
                    font-size: clamp(10px,1vw,12px);
                }
                img{
                    width: 26px;
                    height: 26px;
                }
            }
        }
        .title{
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            word-spacing: 3px;
            .underline{
                width: 50%;
                border-radius: 12px;
                border: 3px solid var(--primary-color);
            }
        }
    }
}
.texts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    row-gap: 16px;
    z-index: 7;
    .body{
        font-weight: 400;
        font-size: clamp(16px,2vw,20px);
        color: var(--grey);
    }
    h2{
        position: relative;
        &::before{
                    height: 45px;
                    width: 52px;
                    content: ' ';
                    position: absolute;
                    right: -30px;
                    background: url('../../assets/icons/bilbilak.svg');
                    background-repeat: no-repeat;
                    z-index: -1;
                }
    }
}
.orgcontainer{
     &::before{
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: url('../../assets/feeBG.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    min-height: 100vh;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 40px;
    padding: 100px 200px ;
    h2{
        z-index: 5;
        align-self: center;
    }
    
}
@media only screen and(max-width:1000px){
    .orgcontainer{
        padding: 20px 30px ;
    }
}
@media only screen and(max-width:1600px){
    .infos{
        width: 30% !important;
    }
}
@media only screen and(max-width:800px){
.content{
    flex-direction: column;
    .infos{
        width: 100% !important;
        border-bottom-right-radius: unset !important;
        border-top-left-radius: 12px;
        row-gap: 20px;
    }
    .contactbanner{
        width: 100%;
    }
}
}
</style>